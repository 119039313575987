var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "bg_box" }, [
        !_vm.isShowPage ? _c("div", [_vm._m(0)]) : _vm._e()
      ]),
      _vm.isShowPage
        ? _c(
            "div",
            { staticClass: "personbox" },
            [
              _c("van-nav-bar", {
                attrs: {
                  title: "我的",
                  "safe-area-inset-top": "",
                  fixed: "",
                  placeholder: "",
                  border: false
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "right",
                      fn: function() {
                        return [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/icon_settings_red_4.png"),
                              alt: ""
                            },
                            on: { click: _vm.loginout }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  1812524791
                )
              }),
              _c("div", { staticClass: "rel" }, [
                _c("div", { staticClass: "phonttop" }),
                _vm.processEnv
                  ? _c("div", { staticClass: "abs topbox" }, [
                      _c("div", { staticClass: "flex" }, [
                        _c(
                          "div",
                          { staticClass: "imgbox" },
                          [
                            _vm.uerinfo.avatar
                              ? [
                                  _c("img", {
                                    attrs: { src: _vm.uerinfo.avatar, alt: "" }
                                  })
                                ]
                              : [
                                  _vm.uerinfo.realName
                                    ? [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/images/pic_head portrait_com@2x.png"),
                                            alt: ""
                                          }
                                        })
                                      ]
                                    : [
                                        _vm.uerinfo.auth == "UNPOST"
                                          ? _c("img", {
                                              attrs: {
                                                src: require("@/assets/images/pic_head portrait_com@2x.png"),
                                                alt: ""
                                              }
                                            })
                                          : _vm._e(),
                                        _vm.uerinfo.auth == "POST"
                                          ? _c("img", {
                                              attrs: {
                                                src: require("@/assets/images/pic_head portrait_com@2x.png"),
                                                alt: ""
                                              }
                                            })
                                          : _vm._e()
                                      ]
                                ]
                          ],
                          2
                        ),
                        _c("div", { staticClass: "namebox" }, [
                          _c("div", [
                            _c("div", { staticClass: "postBox" }, [
                              _vm.uerinfo.realName
                                ? _c("p", [
                                    _vm._v(_vm._s(_vm.uerinfo.realName))
                                  ])
                                : _vm._e(),
                              _vm.uerinfo.auth == "UNPOST" ||
                              _vm.uerinfo.auth == "FAIL"
                                ? _c(
                                    "div",
                                    {
                                      attrs: { "data-info": "certification" },
                                      on: { click: _vm.isAutoGraph }
                                    },
                                    [_vm._m(1)]
                                  )
                                : _vm.uerinfo.auth == "POST"
                                ? _c("div", [_vm._m(2)])
                                : _vm.uerinfo.auth == "DONE"
                                ? _c("div", [
                                    !_vm.uerinfo.hasOwnProperty("sign")
                                      ? _c(
                                          "div",
                                          { staticClass: "pleaseCertify" },
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                attrs: {
                                                  to: {
                                                    name: "AccountContract",
                                                    query: { pricing: true }
                                                  }
                                                }
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: require("@/assets/images/btn_post_examine@2x.png"),
                                                    alt: ""
                                                  }
                                                })
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : !_vm.uerinfo.hasOwnProperty(
                                          "faceVerify"
                                        )
                                      ? _c(
                                          "div",
                                          { staticClass: "pleaseCertify" },
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                attrs: { to: { name: "face" } }
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: require("@/assets/images/btn_post_examine@2x.png"),
                                                    alt: ""
                                                  }
                                                })
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : _c(
                                          "div",
                                          { staticClass: "pleaseCertify" },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: require("@/assets/images/btn_post_examine@2x.png"),
                                                alt: ""
                                              }
                                            })
                                          ]
                                        )
                                  ])
                                : _vm._e()
                            ])
                          ]),
                          _c("p", { staticClass: "phone" }, [
                            _vm._v(_vm._s(_vm.uerinfo.phone))
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "waitHandleBox flex-between" }, [
                        _c("div", { staticClass: "waitHandleDescribe" }, [
                          _vm._v("待处理")
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "waitHandle",
                            on: { click: _vm.openBuy }
                          },
                          [
                            _c("div", { staticClass: "number" }, [
                              _vm._v(
                                _vm._s(_vm.buyMaterials ? _vm.buyMaterials : 0)
                              )
                            ]),
                            _c("div", [_vm._v("买料订单")])
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "waitHandle",
                            on: { click: _vm.openSell }
                          },
                          [
                            _c("div", { staticClass: "number" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.sellMaterials ? _vm.sellMaterials : 0
                                )
                              )
                            ]),
                            _c("div", [_vm._v("卖料订单")])
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "waitHandle",
                            on: { click: _vm.openMortgage }
                          },
                          [
                            _c("div", { staticClass: "number" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.mortgageMaterials
                                    ? _vm.mortgageMaterials
                                    : 0
                                )
                              )
                            ]),
                            _c("div", [_vm._v("存料订单")])
                          ]
                        )
                      ])
                    ])
                  : _vm._e(),
                !_vm.processEnv
                  ? _c("div", { staticClass: "flex abs topbox" }, [
                      _c(
                        "div",
                        { staticClass: "imgbox" },
                        [
                          _vm.uerinfo.avatar
                            ? [
                                _c("img", {
                                  attrs: { src: _vm.uerinfo.avatar, alt: "" }
                                })
                              ]
                            : [
                                _vm.uerinfo.realName
                                  ? [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/images/jqt.png"),
                                          alt: ""
                                        }
                                      })
                                    ]
                                  : [
                                      _vm.uerinfo.auth == "UNPOST"
                                        ? _c("img", {
                                            attrs: {
                                              src: require("@/assets/images/jqt.png"),
                                              alt: ""
                                            }
                                          })
                                        : _vm._e(),
                                      _vm.uerinfo.auth == "POST"
                                        ? _c("img", {
                                            attrs: {
                                              src: require("@/assets/images/jqt.png"),
                                              alt: ""
                                            }
                                          })
                                        : _vm._e()
                                    ]
                              ]
                        ],
                        2
                      ),
                      _c("div", { staticClass: "namebox" }, [
                        _c("div", [
                          _c("div", { staticClass: "postBox" }, [
                            _vm.uerinfo.realName
                              ? _c("p", [_vm._v(_vm._s(_vm.uerinfo.realName))])
                              : _vm._e(),
                            _vm.uerinfo.auth == "UNPOST" ||
                            _vm.uerinfo.auth == "FAIL"
                              ? _c(
                                  "div",
                                  {
                                    attrs: { "data-info": "certification" },
                                    on: { click: _vm.isAutoGraph }
                                  },
                                  [_vm._m(3)]
                                )
                              : _vm.uerinfo.auth == "POST"
                              ? _c("div", [_vm._m(4)])
                              : _vm.uerinfo.auth == "DONE"
                              ? _c("div", [
                                  !_vm.uerinfo.hasOwnProperty("sign")
                                    ? _c(
                                        "div",
                                        { staticClass: "pleaseCertify" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: {
                                                  name: "AccountContract",
                                                  query: { pricing: true }
                                                }
                                              }
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: require("@/assets/images/btn_post_examine@2x.png"),
                                                  alt: ""
                                                }
                                              })
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm.uerinfo.hasOwnProperty("faceVerify")
                                    ? _c(
                                        "div",
                                        { staticClass: "pleaseCertify" },
                                        [
                                          _c(
                                            "router-link",
                                            { attrs: { to: { name: "face" } } },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: require("@/assets/images/btn_post_examine@2x.png"),
                                                  alt: ""
                                                }
                                              })
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        { staticClass: "pleaseCertify" },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("@/assets/images/btn_post_examine@2x.png"),
                                              alt: ""
                                            }
                                          })
                                        ]
                                      )
                                ])
                              : _vm._e()
                          ])
                        ]),
                        _c("p", { staticClass: "phone" }, [
                          _vm._v(_vm._s(_vm.uerinfo.phone))
                        ])
                      ])
                    ])
                  : _vm._e()
              ]),
              _c("div", { staticClass: "topcenter" }, [
                _c("div", { staticClass: "box" }, [
                  _c("h1", [_vm._v("常用功能")]),
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.linkFunciton(0)
                          }
                        }
                      },
                      [_vm._m(5), _c("span", [_vm._v("卖料订单")])]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.linkFunciton(1)
                          }
                        }
                      },
                      [_vm._m(6), _c("span", [_vm._v("买料订单")])]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.linkFunciton(5)
                          }
                        }
                      },
                      [_vm._m(7), _c("span", [_vm._v("换料订单")])]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.linkFunciton(2)
                          }
                        }
                      },
                      [_vm._m(8), _c("span", [_vm._v("存料订单")])]
                    )
                  ]),
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.linkFunciton(4)
                          }
                        }
                      },
                      [_vm._m(9), _c("span", [_vm._v("提料管理")])]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.linkFunciton(7)
                          }
                        }
                      },
                      [_vm._m(10), _c("span", [_vm._v("来料管理")])]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.linkFunciton(8)
                          }
                        }
                      },
                      [_vm._m(11), _c("span", [_vm._v("小金库")])]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.linkFunciton(3)
                          }
                        }
                      },
                      [_vm._m(12), _c("span", [_vm._v("押金")])]
                    )
                  ]),
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.linkFunciton(6)
                          }
                        }
                      },
                      [_vm._m(13), _c("span", [_vm._v("订单报表")])]
                    ),
                    _c("div", { staticClass: "iconBox" }),
                    _c("div", { staticClass: "iconBox" }),
                    _c("div", { staticClass: "iconBox" })
                  ])
                ]),
                _c("div", { staticClass: "box" }, [
                  _c("h1", [_vm._v("其他功能")]),
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.link(8)
                          }
                        }
                      },
                      [_vm._m(14), _c("span", [_vm._v("价格提醒")])]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.link(0)
                          }
                        }
                      },
                      [_vm._m(15), _c("span", [_vm._v("快递查询")])]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.link(1)
                          }
                        }
                      },
                      [_vm._m(16), _c("span", [_vm._v("货运保单")])]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.link(10)
                          }
                        }
                      },
                      [_vm._m(17), _c("span", [_vm._v("我的积分")])]
                    )
                  ]),
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.link(11)
                          }
                        }
                      },
                      [_vm._m(18), _c("span", [_vm._v("快递报单")])]
                    ),
                    _c("div", { staticClass: "iconBox" }),
                    _c("div", { staticClass: "iconBox" }),
                    _c("div", { staticClass: "iconBox" })
                  ])
                ]),
                _c("div", { staticClass: "box" }, [
                  _c("h1", [_vm._v("其他功能")]),
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.link(6)
                          }
                        }
                      },
                      [_vm._m(19), _c("span", [_vm._v("业务说明")])]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.link(2)
                          }
                        }
                      },
                      [_vm._m(20), _c("span", [_vm._v("回收标准")])]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.link(3)
                          }
                        }
                      },
                      [_vm._m(21), _c("span", [_vm._v("银行账号")])]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.link(4)
                          }
                        }
                      },
                      [_vm._m(22), _c("span", [_vm._v("常见问题")])]
                    )
                  ]),
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.link(9)
                          }
                        }
                      },
                      [_vm._m(23), _c("span", [_vm._v("邀请好友")])]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.link(7)
                          }
                        }
                      },
                      [_vm._m(24), _c("span", [_vm._v("关于我们")])]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.link(5)
                          }
                        }
                      },
                      [_vm._m(25), _c("span", [_vm._v("联系客服")])]
                    ),
                    _c("div", { staticClass: "iconBox" })
                  ])
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _c(
        "van-dialog",
        {
          model: {
            value: _vm.cershow,
            callback: function($$v) {
              _vm.cershow = $$v
            },
            expression: "cershow"
          }
        },
        [
          _c("div", { staticClass: "title" }, [_vm._v("提示")]),
          _c("div", { staticClass: "centerbox_dialog" }, [
            _vm._v("您的账号还未认证，暂无此项权限")
          ]),
          _c(
            "div",
            { staticClass: "gocer flex-around" },
            [
              _c(
                "div",
                {
                  staticClass: "canclebox",
                  on: {
                    click: function($event) {
                      _vm.cershow = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c("router-link", { attrs: { to: { name: "certification" } } }, [
                _vm._v("去认证")
              ])
            ],
            1
          )
        ]
      ),
      _c(
        "van-dialog",
        {
          model: {
            value: _vm.ceringModel,
            callback: function($$v) {
              _vm.ceringModel = $$v
            },
            expression: "ceringModel"
          }
        },
        [
          _c("div", { staticClass: "title" }, [_vm._v("提示")]),
          _c("div", { staticClass: "centerbox_dialog" }, [
            _vm._v("您的认证信息正在审核中，请稍后查看")
          ]),
          _c(
            "div",
            {
              staticClass: "gocer",
              on: {
                click: function($event) {
                  _vm.ceringModel = false
                }
              }
            },
            [_vm._v("确定")]
          )
        ]
      ),
      _c(
        "van-dialog",
        {
          attrs: { "show-confirm-button": false },
          model: {
            value: _vm.showOtherPickUp,
            callback: function($$v) {
              _vm.showOtherPickUp = $$v
            },
            expression: "showOtherPickUp"
          }
        },
        [
          _c("div", { staticClass: "title" }, [_vm._v("新增物流信息")]),
          _c("div", { staticClass: "centerbox_dialog" }, [
            _c(
              "div",
              { staticClass: "flex orderInfo topCol" },
              [
                _c("van-field", {
                  attrs: {
                    label: "快递单号",
                    placeholder: "请输入快递单号",
                    "input-align": "right",
                    size: "24px",
                    type: "text"
                  },
                  model: {
                    value: _vm.OtherPickUpForm.expressNo,
                    callback: function($$v) {
                      _vm.$set(_vm.OtherPickUpForm, "expressNo", $$v)
                    },
                    expression: "OtherPickUpForm.expressNo"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex orderInfo topCol" },
              [
                _c("van-field", {
                  attrs: {
                    label: "重量",
                    placeholder: "请输入货品克重",
                    "input-align": "right",
                    size: "24px",
                    type: "number"
                  },
                  model: {
                    value: _vm.OtherPickUpForm.totalWeightGram,
                    callback: function($$v) {
                      _vm.$set(_vm.OtherPickUpForm, "totalWeightGram", $$v)
                    },
                    expression: "OtherPickUpForm.totalWeightGram"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex orderInfo topCol" },
              [
                _c("van-field", {
                  attrs: {
                    label: "快递公司",
                    placeholder: "请输入快递公司",
                    "input-align": "right",
                    size: "24px",
                    type: "text"
                  },
                  model: {
                    value: _vm.OtherPickUpForm.expressCompany,
                    callback: function($$v) {
                      _vm.$set(_vm.OtherPickUpForm, "expressCompany", $$v)
                    },
                    expression: "OtherPickUpForm.expressCompany"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex orderInfo topCol" },
              [
                _c("van-field", {
                  attrs: {
                    label: "手机尾号",
                    placeholder: "收件人或寄件人手机尾号后四位",
                    "input-align": "right",
                    size: "24px",
                    type: "number"
                  },
                  model: {
                    value: _vm.OtherPickUpForm.phone,
                    callback: function($$v) {
                      _vm.$set(_vm.OtherPickUpForm, "phone", $$v)
                    },
                    expression: "OtherPickUpForm.phone"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex orderInfo topCol" },
              [
                _c("van-field", {
                  attrs: {
                    label: "备注",
                    placeholder: "请输入备注",
                    "input-align": "right",
                    size: "24px",
                    type: "textarea"
                  },
                  model: {
                    value: _vm.OtherPickUpForm.remark,
                    callback: function($$v) {
                      _vm.$set(_vm.OtherPickUpForm, "remark", $$v)
                    },
                    expression: "OtherPickUpForm.remark"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "tips" }, [
              _vm._v(" 注：不填写收件人或者寄件人手机尾号后四位将影响物流查询 ")
            ])
          ]),
          _c("div", { staticClass: "flex-between popbtn" }, [
            _c(
              "div",
              { staticClass: "gocer closebtn", on: { click: _vm.close } },
              [_vm._v("取消")]
            ),
            _c(
              "div",
              { staticClass: "gocer confirmbtn", on: { click: _vm.confirm } },
              [_vm._v("确定")]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "loading" }, [
      _c("span"),
      _c("span"),
      _c("span"),
      _c("span"),
      _c("span")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pleaseCertify" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/btn_authentication@2x.png"),
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pleaseCertify" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/btn_examine@2x.png"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pleaseCertify" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/btn_authentication@2x.png"),
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pleaseCertify" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/btn_examine@2x.png"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgminbox" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/icon_person_sell_red@2x.png"),
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgminbox" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/icon_person_buy_red@2x.png"),
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgminbox" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/icon_person_changeMaterial_red@2x.png"),
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgminbox" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/icon_person_stock_blue@2x.png"),
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgminbox" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/icon_person_material_blue@2x.png"),
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgminbox" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/icon_person_incoming_blue@2x.png"),
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgminbox" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/icon_person_storeroom_yellow@2x.png"),
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgminbox" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/icon_person_deposit_yellow@2x.png"),
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgminbox" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/icon_person_orderData_yellow@2x.png"),
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgminbox" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/icon_person_remind_red@2x.png"),
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgminbox" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/icon_person_express_red@2x.png"),
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgminbox" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/icon_person_insured_red@2x.png"),
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgminbox" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/icon_person_integral_red@2x.png"),
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgminbox" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/icon_person_pickup_red@2x.png"),
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgminbox" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/icon_person_business_grey@2x.png"),
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgminbox" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/icon_person_condition_grey@2x.png"),
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgminbox" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/icon_person_bankCard_grey@2x.png"),
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgminbox" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/icon_person_wenhao_grey@2x.png"),
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgminbox" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/icon_person_inviteFriends_grey@2x.png"),
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgminbox" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/icon_person_about_grey@2x.png"),
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgminbox" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/icon_person_online_grey@2x.png"),
          alt: ""
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }